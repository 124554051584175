import { Component, ViewChild, ViewEncapsulation, OnInit, ElementRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Site } from 'achelous';
import { SharedService } from '../../services/shared.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'mhl-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ControlsComponent implements OnInit {

  private domEl: HTMLElement;

  public emptyLabel: string = 'Please select a site to add to this controls panel';
  public theme: string = '';
  public fillBg: boolean = false;
  public chartInterval: string;
  public ahd: boolean;
  public autoSelect: boolean;
  public selectable: boolean;
  public downloadable: boolean;
  public defaultType: string;
  public defaultValue: any;
  public display: boolean = true;
  public controlbar: any;

  public sites: Site[] = [];

  public sensorsForCharts: String[];
  public sensorIds: string;
  public today: Date = new Date();
  public dates: any; // TODO: interface?

  @ViewChild('controlsTable') public table: any;

  public selected: Site[] = [];
  public expanded: any = {};
  public timeout: any;
  private changes: MutationObserver;

  constructor(
    private elRef: ElementRef,
    public _helperService: HelperService,
    private _sharedService: SharedService
  ) {
    this.domEl = elRef.nativeElement as HTMLElement;
    this._sharedService.isControls = true;    
    const emptyLabel = this.domEl.getAttribute('emptyLabel');
    emptyLabel && emptyLabel !== '' ? this.emptyLabel = emptyLabel : this.emptyLabel = 'Please select a site to add to this controls panel';

    const theme = this.domEl.getAttribute('theme');
    theme && theme !== '' ? this.theme = theme : this.theme = ''; // TODO: implement default interface
    
    const chartInterval = this.domEl.getAttribute('chartInterval');
    chartInterval && chartInterval !== '' ? this.chartInterval = chartInterval : this.chartInterval = ''; // TODO: implement default interface

    const ahd = this.domEl.getAttribute('ahd');
    ahd && ahd !== '' ? this.ahd = _helperService.parseAttribute('boolean', ahd, false, 'ahd') as boolean : this.ahd = false;
    
    const autoSelect = this.domEl.getAttribute('autoselect');
    autoSelect && autoSelect !== '' ? this.autoSelect = _helperService.parseAttribute('boolean', autoSelect, true, 'autoSelect') as boolean : this.autoSelect = true; // TODO: defaults?

    const selectable = this.domEl.getAttribute('selectable');
    selectable && selectable !== '' ? this.selectable = _helperService.parseAttribute('boolean', selectable, true, 'selectable') as boolean : this.selectable = true;
    
    const display = this.domEl.getAttribute('display');
    display && display !== '' ? this.display = _helperService.parseAttribute('boolean', display, true, 'display') as boolean : '';

    this.controlbar = {opened: false,closeOutside: true,loading: false};
    this.changes = new MutationObserver((mutations: MutationRecord[]) => { 
        mutations.forEach( (c: MutationRecord) => {                 
            if (c.attributeName == 'defaultvalue') {
                const codes = this.domEl.getAttribute('defaultValue');
                if (codes && codes !== ''){
                    const parsedCodes = this._helperService.parseAttribute('json', codes, [], codes) as string[];
                    if (parsedCodes.length == 0){
                        this._sharedService.updateSelectedSitesForControls([],true);
                        } else {
                    this._sharedService.allSites$.pipe(takeWhile(val => val.length == 0,true)).subscribe(async allSites => {
                    if (allSites.length > 0){
                        let sites = parsedCodes.map(c => allSites.find(site => site.sitecode == c)).filter(sites=>sites);
                        this._sharedService.chartInterval = this.chartInterval;
                        this._sharedService.updateSelectedSitesForControls(sites,true);
                        this.sites = [...sites];
                        }
                        });
                        }
                } else {
                    this._sharedService.updateSelectedSitesForControls([...this.sites]);
                }
            }
        });
      });

    const defaultType = this.domEl.getAttribute('defaultType');
    if (defaultType && defaultType !== '') {
      this.addDefaultSelections(defaultType);
    }
  }

  ngOnInit(): void {
    this._sharedService.selectedSitesForControls$.subscribe(sites => {
      //test if sites already exist in component
      if (sites && sites.length > 0) {
          this.loadAll(sites);
     } else if (sites && sites.length == 0){
        const reads = this._sharedService.clearReadings();
     }
    });

    this._sharedService.sensorsForCharts$.subscribe(sensors => {
      // console.log('sensors for charts', sensors);
      this.sensorsForCharts = sensors;
      this.sensorIds = this.sensorsForCharts.join(',');
    });
  }

  public toggleExpandRow(row) {
    // console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  public onDetailToggle(event): void {
    // console.log('Detail Toggled', event);
  }
  public loadAll(sites: Site[]): void {
  //DEBUG console.log('loaded',sites);
    let sensors = [];
    const updateSites = this._helperService.diffArray(sites,this.sites);
    for (const s of updateSites){
      sensors = sensors.concat(s.sensor_ids);
    }
    this.sites = [...sites];
    this.selected = [...sites];
    this._sharedService.chartInterval = this.chartInterval;
    this._sharedService.ahd = this.ahd;
    if (this._sharedService.isChart || this._sharedService.isTable) {
        this._sharedService.updateSelectedSitesForCharts(this.sites);
        if (this.sensorsForCharts.length == 0){
            this._sharedService.getReadingsFromSensors(true, sensors);
        } else {
            this._sharedService.updateSensorsForCharts(sensors);
            const useSensors = this.sites.map(s => s.sensor_ids).flat();
            this._sharedService.updateReadingsFromSensors(useSensors);
        }
    }
    if (this.autoSelect){
        this._sharedService.updateSelectedSites(this.selected);
    }
  }
  public onSelect(selected): void {
    //DEBUG console.log('selected',selected);
    let sensors = [];
    let old = this.sites;
    if (selected.hasOwnProperty('selected')){
        selected = selected.selected;
        this.selected = selected;
    } else { //comes from update of selectedsitesforcontrols
      let badInd = [];
      for (const s of selected) {
        const ind = this.selected.indexOf(s) 
        if (ind >= 0){
          badInd.push(ind);
        }
      }
      badInd.sort().reverse()
      if (badInd.length > 0){
        badInd.forEach(v =>{
          this.selected.splice(v,1);
        });

      } else {        
        this.selected.push(...selected);
      }
    }
    this.selected.forEach(site => {
      sensors.push(...site.sensor_ids);
    });
    this._sharedService.chartInterval = this.chartInterval;
    if (this._sharedService.isChart || this._sharedService.isTable) {
        this._sharedService.updateSelectedSitesForCharts(this.selected);
        this._sharedService.updateReadingsFromSensors(sensors);
    }
    if (this.autoSelect){
       this._sharedService.updateSelectedSites(this.selected); 
    }
  }
  public toggleSidebar() {
    this.controlbar.opened = !this.controlbar.opened;
  }
  public toggleSensor(sensor: string, event?: any): void {
    // console.log('toggled sensor', sensor, event.target.checked);
    this._sharedService.updateSensorsForCharts(sensor); //??
    this._sharedService.updateReadingsFromSensors([sensor]);
  }

  private async addDefaultSelections(type?: string): Promise<void> {
    if (!type){
      type = this.defaultType;
    }
    const attr = 'defaultType';
    const errorMessage = `You set the '${attr}' attribute value to '${type}' but did not provide value for 'this.defaultValue' attribute.\n\nNo sites will be preselected`;

    const defaultValue = this.domEl.getAttribute('defaultValue');
    this.defaultValue = defaultValue;
    if (defaultValue && defaultValue !== '') {
      switch (type) {
        /*
        case 'url':
          if (this._helperService.isUrl(this.defaultValue)) {
            // TODO: complete
          } else {
            console.error(`'defaultValue' attribute nust be a url if 'defaultType' is set 'url'.`);
          }
          break;
          */
        case 'codes':
          this.changes.observe(this.domEl,{attributes: true});
          if (this.defaultValue == 'none') break;
          const parsedDefaultCodes = this._helperService.parseAttribute('json', this.defaultValue, [], 'this.defaultValue') as string[];
          this._sharedService.allSites$.pipe(takeWhile(val => val.length == 0,true)).subscribe(async allSites => {
              if (allSites.length > 0){
                let sites = parsedDefaultCodes.map(c=> allSites.find(s=> s.sitecode == c));
                if (!sites.every(s=> s !== undefined)){
                    sites = sites.filter(s => s);
                    const badcodes = parsedDefaultCodes.filter(c => !sites.map(s => s.sitecode).includes(c));
                    console.warn('The following sites could not be found in allSites and will loaded temporarily:', badcodes);
                    const newsites = await this._sharedService.getSitesFromSitecodes(badcodes);
                    sites = [...sites,...newsites]
                };
                this._sharedService.chartInterval = this.chartInterval;
                this._sharedService.updateSelectedSitesForControls(sites);
              }
          });
          break;
        case 'collectionIds':
          console.log('case collectionsIds')
          const parsedDefaultCollectionIds = this._helperService.parseAttribute('json', this.defaultValue, [], 'this.defaultValue') as string[];
          console.log('control 243 parsedDefaultCollectionIds:',parsedDefaultCollectionIds)
          combineLatest(this._sharedService.collectionsList$,this._sharedService.allSites$)
          .pipe(takeWhile(([c,s]) => !(c && c.length > 0) || !(s && s.length  > 0), true)).subscribe(async ([collections,sites]) => {
              if (collections && sites && collections.length > 0 && sites.length >0){
                  console.log('control 247 collections:',collections)
                  console.log('control 248 sites:', sites)
                  const colIds = collections.map(c => c.id);
                  console.log('control 250 colIds:', colIds)
                  if (!(parsedDefaultCollectionIds as string[]).every(c => colIds.includes(c))){
                    console.log('control 252 going to addNewCollection()')
                    await this._sharedService.addNewCollection(parsedDefaultCollectionIds as string[]);
                  }
                  const collectionSites = await this._sharedService.filterSitesByCollection(parsedDefaultCollectionIds,sites);
                  console.log('control 256 collectionSites', collectionSites)
                  this._sharedService.chartInterval = this.chartInterval;
                  this._sharedService.updateSelectedSitesForControls(collectionSites);                  
              }

          });
          // console.log('parsedDefaultCollectionIds', parsedDefaultCollectionIds);
          // console.log('sitesFromCollections', sitesFromCollections);
          break;
        default:
          console.error(`Provided value for '${attr}' attribute is not supported, please use one of the following: 'url', 'collectionIds', 'codes'\n\nNo sites will be preselected`);
      }
    } else {
      console.error(errorMessage);
    }

  }

}
